import { useState, FC } from 'react';

import { IInstallment, InstallmentsContext } from '../../hooks/useInstallments';

export const InstallmentsProvider: FC = ({ children }) => {
  const [selectedInstallment, setSelectedInstallment] = useState<IInstallment>({
    number: 1,
    value: 0,
  });

  const [isInstallmentsAvailable, setIsInstallmentsAvailable] =
    useState<boolean>(false);

  return (
    <InstallmentsContext.Provider
      value={{
        selectedInstallment,
        setSelectedInstallment,

        isInstallmentsAvailable,
        setIsInstallmentsAvailable,
      }}
    >
      {children}
    </InstallmentsContext.Provider>
  );
};

export const cardForm = {
  'Name printed on card': 'Nombre impreso en la tarjeta',
  'Card number': 'Número de tarjeta',
  'Expiry date': 'Fecha de caducidad',
  Cvc: 'Código de seguridad',
  'Better billing day': 'Mejor día de facturación',
  Installments: 'Cuotas',

  'Card name can not have less than 2 and more than 26 characters':
    'El nombre de la tarjeta no puede tener menos de 2 y más de 26 caracteres.',
  'You must inform the billing day': 'Debe informar el día de facturación',

  'The day must be greater than or equal to {{day}}':
    'El día debe ser mayor o igual a {{day}}',
  'The day must be lower than or equal to {{day}}':
    'El día debe ser menor o igual a {{day}}',

  'Inform the card number to be able select installments':
    '* Informar el número de tarjeta para poder seleccionar cuotas.',
  'For this purchase, the maximum installment value is {{value}}':
    '* Para esta compra, el valor máximo de la cuota es de {{value}}',
};

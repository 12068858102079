import { createContext, useContext } from 'react';

import { isEmpty } from 'lodash';

export interface IInstallment {
  number: number;
  value: number;
}

interface IInstallmentsContext {
  selectedInstallment: IInstallment;
  setSelectedInstallment(installment: IInstallment): void;

  isInstallmentsAvailable: boolean;
  setIsInstallmentsAvailable(isAvailable: boolean): void;
}

export const InstallmentsContext: React.Context<IInstallmentsContext> =
  createContext({} as IInstallmentsContext);

export const useInstallments = () => {
  const context = useContext(InstallmentsContext);

  if (isEmpty(context)) {
    throw new Error(
      'useInstallments should be called within a InstallmentsContext',
    );
  }

  return context;
};
